var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-card",
        { staticClass: "v-application jobs-overview" },
        [
          _c(
            "v-simple-table",
            {
              staticClass: "v-application--is-ltr",
              attrs: { "mobile-breakpoint": "670" },
            },
            [
              _c("thead", [
                _c("tr", [
                  _c("th", [_vm._v("#")]),
                  _vm._v(" "),
                  _c("th", [_vm._v("Name")]),
                  _vm._v(" "),
                  _c("th", [_vm._v("Detail")]),
                  _vm._v(" "),
                  _c("th", { staticClass: "text-right" }, [_vm._v("Actions")]),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "tbody",
                _vm._l(_vm.jobs, function (item, index) {
                  return _c("tr", { key: item.id }, [
                    _c("td", [_vm._v(_vm._s(index + 1))]),
                    _vm._v(" "),
                    _c("td", [_vm._v(_vm._s(item.name))]),
                    _vm._v(" "),
                    _c("td", [_vm._v(_vm._s(item.description))]),
                    _vm._v(" "),
                    _c(
                      "td",
                      { staticClass: "text-right" },
                      [
                        _c(
                          "v-btn",
                          {
                            staticClass: "mx-2",
                            attrs: {
                              color: _vm.isLoading ? "grey" : "primary",
                              disabled: _vm.isLoading,
                              loading: _vm.isLoading,
                              small: "",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.triggerJobById(item.id)
                              },
                            },
                          },
                          [
                            _vm.isLoading
                              ? _c(
                                  "v-icon",
                                  {
                                    staticClass: "rotate-hourglass",
                                    attrs: { dark: "", size: "19" },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                mdi-timer-sand\n                            "
                                    ),
                                  ]
                                )
                              : _c(
                                  "v-icon",
                                  { attrs: { dark: "", size: "19" } },
                                  [
                                    _vm._v(
                                      "\n                                mdi-play\n                            "
                                    ),
                                  ]
                                ),
                            _vm._v(" "),
                            _c("span", { staticClass: "ml-2" }, [
                              _vm._v("RUN"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ])
                }),
                0
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }