<template>
    <v-dialog
        v-model="isOpen"
        content-class="v-application v-application--is-ltr"
        max-width="580px"
        :fullscreen="!$vuetify.breakpoint.mdAndUp"
    >
        <v-card>
            <v-card-title></v-card-title>
            <v-card-text>
                <v-alert
                    dense
                    outlined
                    color="error"
                    icon="mdi-alert-circle"
                >
                    Kommentar Löschen?
                </v-alert>
            </v-card-text>
            <v-card-actions class="justify-end">
                <v-btn
                    @click="isOpen = false"
                    outlined
                    color="secondary"
                >
                    Abbrechen
                </v-btn>
                <v-btn
                    @click="onDelete"
                    depressed
                    color="error"
                    class="confirm-delete-btn"
                >
                    Löschen
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>


<script>
    export default {
        props: {
            value: Boolean,
            appointmentId: String,
            pharmacyId: String,
        },
        computed: {
            isOpen: {
                get() {
                    return this.value
                },
                set(state) {
                    this.$emit('input', state)
                }
            },
        },
        methods: {
            onDelete() {
                this.$emit('confirm-deletion');
                this.isOpen = false
            }
        },
    }
</script>

<style scoped>

</style>