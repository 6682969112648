<template>
    <div>
        <v-card class="v-application jobs-overview">
            <v-simple-table
                class="v-application--is-ltr"
                mobile-breakpoint="670"
            >
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>Detail</th>
                        <th class="text-right">Actions</th>
                    </tr>
                </thead>

                <tbody>
                    <tr v-for="(item, index) in jobs" :key="item.id">
                        <td>{{ index + 1 }}</td>
                        <td>{{ item.name }}</td>
                        <td>{{ item.description }}</td>
                        <td class="text-right">
                            <v-btn
                                @click="triggerJobById(item.id)"
                                class="mx-2"
                                :color="isLoading ? 'grey' : 'primary'"
                                :disabled="isLoading"
                                :loading="isLoading"
                                small
                            >
                                <v-icon dark size="19" v-if="isLoading" class="rotate-hourglass">
                                    mdi-timer-sand
                                </v-icon>
                                <v-icon dark size="19" v-else>
                                    mdi-play
                                </v-icon>
                                <span class="ml-2">RUN</span>
                            </v-btn>
                        </td>
                    </tr>
                </tbody>
            </v-simple-table>
        </v-card>
    </div>
</template>


<script>
    import axios from 'axios';
    import { mapMutations } from 'vuex';

    export default {
        name: 'JobsOverviewTable',
        props: {
        },
        data: () => ({
            jobs: [],
            isLoading: false,
        }),
        methods: {
            async fetchJobs() {
                try {
                    const response = await axios.get(`/api/jobs`);
                    this.jobs = response.data;
                } catch(error) {
                    this.alertError()
                    console.error("failed to fetch job list.", error);
                }
            },
            async triggerJobById(id) {
                try {
                    this.isLoading = true;
                    await axios.put(`/api/jobs/${id}/trigger`);
                    this.alertSuccess("Der Job wurde erfolgreich ausgeführt");
                } catch(error) {
                    this.alertError()
                    console.error(`failed to trigger job - ${id}.`);
                } finally {
                    this.isLoading = false
                }
            },
            ...mapMutations({
                alertError: 'alert/error',
                alertSuccess: 'alert/success',
            })
        },
        async mounted() {
            await this.fetchJobs();
        }
    }
</script>

<style scoped>
    .jobs-overview {
        margin-top: 20px
    }

    @keyframes rotate-pause {
        0% {
            transform: rotate(0deg);
        }
        40% {
            transform: rotate(180deg);
        }
        60% {
            transform: rotate(180deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }

    .rotate-hourglass {
        animation: rotate-pause 4s ease-in-out infinite;
    }
</style>