<template>
    <div class="vuetify-wrapper">
        <v-row
            elevation="1"
            class="v-application v-application--is-ltr m-0"
        >
            <v-col cols="12">
                <Thread
                    v-for="thread of threads"
                    :thread="thread"
                    :key="thread._id"
                />
            </v-col>
            <v-col cols="12">
                <v-textarea
                    v-model="threadText"
                    :disabled="isLoading"
                    class="mt-5 thread-textarea"
                    placeholder="Starte eine Konversation"
                    auto-grow
                    outlined
                />
                <v-btn
                    @click="createThread"
                    color="primary"
                    class="add-thread"
                    :disabled="!threadText?.trim().length || isLoading"
                >
                    Senden
                </v-btn>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import moment from 'moment';
import { mapMutations, mapActions, mapGetters } from 'vuex';
import Thread from "./Thread.vue";
const DATE_FORMAT = 'DD-MM-YYYY, HH:mm';

export default {
    props: [
        "admin_id",
        "pharmacist_id",
        "pharmacy_name",
        "appointment_id",
        "appointment_date"
    ],
    components: {
        Thread,
    },
    data() {
        return {
            isLoading: false,
            threadText: this.pharmacy_name ?
                `Deine Anfrage für den ${this.appointment_date} in ${this.pharmacy_name}` : '',
        }
    },
    methods: {
        async createThread() {
            this.isLoading = true;
            await this.addThread({
                appointmentId: this.appointment_id,
                adminId: this.admin_id,
                pharmacistId: this.pharmacist_id,
                threadText: this.threadText
            })
            this.threadText = '';
            this.isLoading = false;
        },
        formatDate(date) {
            return moment(date).format(DATE_FORMAT)
        },
        ...mapMutations({
            alertError: 'alert/error',
        }),
        ...mapActions({
            getThreads: 'threads/getThreads',
            addThread: 'threads/addThread'
        }),
    },
    computed: {
        ...mapGetters(
            'threads',
            ['getPharmacistThreads', 'getPharmacistThreadsByAppointmentId']
        ),
        threads() {
            return this.appointment_id
                ? this.getPharmacistThreadsByAppointmentId(this.pharmacist_id, this.appointment_id)
                : this.getPharmacistThreads(this.pharmacist_id)
        }
    },
    created() {
        this.getThreads(this.pharmacist_id)
    },
}
</script>

<style>
.avatar-bg {
    background-color: #fff;
}
.comment-actions {
    padding-left: 18px !important;
    font-size: 0.95rem;
}
.comment-actions a{
    color: #337AB7 !important;
}
.comment-box {
    max-width: 800px
}
.edit-comment, .delete-comment, .close-thread {
    cursor: pointer;
}
.reopen, .expand {
    pointer-events: all;
    opacity: 1;
}
</style>