<template>
    <v-menu
        v-model="isOpen"
        transition="slide-y-transition"
        :close-on-content-click="false"
        content-class="v-application v-application--is-ltr"
        nudge-top="80"
    >
        <template v-slot:activator="{ on }">
            <v-btn v-on="on" class="ml-2" x-small>
                Weitere Infos
            </v-btn>
        </template>
        <v-card v-if="isOpen">
            <v-card-title class="justify-end py-1 px-2">
                <v-btn @click="isOpen = false" x-small fab text>
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <h4 class="d-inline-block">{{ user.firstname }} {{ user.lastname }}</h4>
                <a
                    :href="`/pharmacists/${user._id}`"
                    target="_blank">
                        anzeigen
                </a>
                |
                <a
                    :href="navigationURL"
                    target="_blank">
                        Maps
                </a>

                <p class="mb-1">{{ pharmacyErp }} Kenntnisse: {{ erpExpertise }}/3 ({{ erpCounts }})</p>

                <div v-if="user.proposed">
                    <p class="mb-0 p-0">Vorgeschlagen: {{ formatDate(user.proposed_date) }}</p>
                    <v-btn
                        small
                        rounded
                        text
                        outlined
                        @click="$emit('unpropose')"
                        :disabled="isLoading"
                        class="mt-1 mb-2"
                    >
                        Vorschlag zurücknehmen
                    </v-btn>
                </div>
                <vc-calendar
                    :columns="$screens({ default: 1, lg: 1 })"
                    :attributes="attributes"
                    :from-page="calendarDate"
                />
            </v-card-text>
        </v-card>
    </v-menu>
</template>

<script>
import axios from 'axios';

export default {
    props: ['user', 'appointmentDate', 'pharmacyErp', 'pharmacyLocation'],
    data() {
        return {
            isOpen: false,
            dates: {}
        }
    },
    methods: {
        async getPharmacistAvailabilityDates() {
            try {
                const response = await axios(`/pharmacists/${this.user.id}/details`);
                if (response.status >= 400) throw new Error();
                this.dates = {
                    'availableDates': response.data.available_dates,
                    'allocatedDates': response.data.allocated_dates,
                    'unavailableDates': response.data.unavailable_dates,
                    'availableByRequestDates': response.data.available_by_request_dates,
                }
            } catch (error) {
                console.error(error)
            }
        },
        formatDate(isoString) {
            if(!isoString.endsWith("Z")) {  // Python 3.9's date serializing doesn't add the Z for UTC
                isoString += "Z";
            }
            return moment(isoString).format("YYYY-MM-DD HH:mm:SS")
        },
    },
    computed: {
        attributes() {
            if (!this.dates.availableDates) return
            return [
                {
                    highlight: 'green',
                    dates: [ ...this.dates.availableDates ],
                },
                {
                    highlight: 'orange',
                    dates: [ ...this.dates.allocatedDates ],
                },
                {
                    highlight: 'red',
                    dates: [ ...this.dates.unavailableDates ],
                },
                {
                    highlight: 'blue',
                    dates: [ ...this.dates.availableByRequestDates ],
                }
            ]
        },
        navigationURL() {
            const params = `origin=${this.user.location.coordinates[1]},${this.user.location.coordinates[0]}`+
            `&destination=${this.pharmacyLocation.coordinates[1]},${this.pharmacyLocation.coordinates[0]}`;
            return `https://www.google.com/maps/dir/?api=1&${params}`
        },
        erpExpertise(){
            return this.user.erp_expertise[this.pharmacyErp];
        },
        erpCounts(){
            return this.user.erp_counts[this.pharmacyErp];
        },
        calendarDate() {
            const date = new Date(this.appointmentDate);
            return {
                month: date.getMonth() + 1,
                year: date.getFullYear()
            }
        },
    },
    watch: {
        isOpen(open) {
            if (open) this.getPharmacistAvailabilityDates()
        }
    },
}
</script>
<style></style>