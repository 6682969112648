var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        "max-width": "1300px",
        "content-class": "v-application v-application--is-ltr vuetify-wrapper",
        fullscreen: !_vm.$vuetify.breakpoint.mdAndUp,
      },
      model: {
        value: _vm.isOpen,
        callback: function ($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen",
      },
    },
    [
      _c(
        "v-card",
        { staticClass: "overflow-auto", attrs: { color: "#f3f3f3" } },
        [
          _c(
            "v-card-text",
            [
              _c("PharmacistThreadsList", {
                attrs: {
                  pharmacist_id: _vm.pharmacistId,
                  admin_id: _vm.currentUser._id,
                  pharmacy_name: _vm.pharmacyName,
                  appointment_id: _vm.appointmentId,
                  appointment_date: _vm.appointmentDate,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }