<template>
    <v-menu min-width="400" allow-overflow max-height="500">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            @click="$emit('get-deleted-comments')"
            text
            depressed
            fab
            small
          >
            <v-icon color="grey darken-2">mdi-information-outline</v-icon>
          </v-btn>
        </template>
        <v-list v-if="comments && comments.length">
            <v-list-item
                v-for="comment in comments"
                class="d-block"
            >
                <v-list-item-content>
                    <v-list-item-title>
                        <h5 class="text-h4">{{ comment.author_name }}</h5>
                    </v-list-item-title>
                    <v-list-item-subtitle>{{ comment.timestamp.split('T')[0] }}</v-list-item-subtitle>
                    <p class="mt-2 text-h6">{{ comment.message }}</p>
                </v-list-item-content>
                <v-divider color="black"></v-divider>
            </v-list-item>
        </v-list>
        <v-list v-else class="text-center">Nein kommentar</v-list>
    </v-menu>
</template>

<script>
export default {
    props: ['comments']
}
</script>