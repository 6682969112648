var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "vuetify-wrapper" },
    [
      _c(
        "v-row",
        {
          staticClass: "v-application v-application--is-ltr m-0",
          attrs: { elevation: "1" },
        },
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            _vm._l(_vm.threads, function (thread) {
              return _c("Thread", {
                key: thread._id,
                attrs: { thread: thread },
              })
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c("v-textarea", {
                staticClass: "mt-5 thread-textarea",
                attrs: {
                  disabled: _vm.isLoading,
                  placeholder: "Starte eine Konversation",
                  "auto-grow": "",
                  outlined: "",
                },
                model: {
                  value: _vm.threadText,
                  callback: function ($$v) {
                    _vm.threadText = $$v
                  },
                  expression: "threadText",
                },
              }),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  staticClass: "add-thread",
                  attrs: {
                    color: "primary",
                    disabled: !_vm.threadText?.trim().length || _vm.isLoading,
                  },
                  on: { click: _vm.createThread },
                },
                [_vm._v("\n                Senden\n            ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }