var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      staticClass: "px-4 py-4 mt-5 rounded-lg",
      attrs: {
        loading: _vm.isLoading,
        disabled: _vm.status === "closed" || _vm.isLoading,
        "data-thread-id": _vm.thread._id,
        outlined: "",
      },
    },
    [
      _c(
        "v-list-item",
        { staticClass: "grow" },
        [
          _c(
            "v-list-item-avatar",
            { attrs: { size: "60", color: "grey darken-3" } },
            [
              _c(
                "object",
                {
                  attrs: {
                    data: `/media/avatar/${_vm.thread.participants.admin}/small`,
                    type: "image/jpg",
                  },
                },
                [
                  _c("v-icon", { attrs: { size: "60" } }, [
                    _vm._v("mdi-account-circle"),
                  ]),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "v-list-item-content",
            [
              _c(
                "v-list-item-title",
                { staticClass: "d-flex justify-space-between text-h5" },
                [_c("span", [_vm._v(_vm._s(_vm.thread.author_name))])]
              ),
              _vm._v(" "),
              _c("v-list-item-subtitle", [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.formatDate(_vm.thread.created_at)) +
                    "\n            "
                ),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            [
              _vm.status === "open"
                ? _c(
                    "v-btn",
                    {
                      attrs: { text: "", depressed: "", fab: "", small: "" },
                      on: {
                        click: function ($event) {
                          return _vm.changeThreadStatus("closed")
                        },
                      },
                    },
                    [
                      _c("v-icon", { attrs: { size: "30" } }, [
                        _vm._v("mdi-cancel"),
                      ]),
                    ],
                    1
                  )
                : _c(
                    "v-btn",
                    {
                      staticClass: "reopen",
                      attrs: { outlined: "", rounded: "", small: "" },
                      on: {
                        click: function ($event) {
                          return _vm.changeThreadStatus("open")
                        },
                      },
                    },
                    [_vm._v("\n                offene\n            ")]
                  ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-card-text",
        { staticClass: "text-h6 d-flex justify-space-between" },
        [
          _c("span", [
            _vm._v("\n            " + _vm._s(_vm.thread.title) + "\n        "),
          ]),
          _vm._v(" "),
          _c(
            "div",
            [
              _c("PharmacistThreadDeletedComments", {
                attrs: { comments: _vm.deletedComments },
                on: { "get-deleted-comments": _vm.getDeletedComments },
              }),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  staticClass: "px-0 expand",
                  attrs: { depressed: "", "min-width": "30", width: "30" },
                  on: {
                    click: function ($event) {
                      _vm.isExpanded = !_vm.isExpanded
                    },
                  },
                },
                [
                  _c("v-icon", { attrs: { size: "30" } }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(
                          _vm.isExpanded ? "mdi-menu-down" : "mdi-menu-up"
                        ) +
                        "\n                "
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "v-card-actions",
        { staticClass: "v-application d-block" },
        [
          _c("v-expand-transition", [
            _c(
              "section",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isExpanded,
                    expression: "isExpanded",
                  },
                ],
              },
              [
                _vm.comments[_vm.thread._id]
                  ? _c(
                      "v-timeline",
                      { attrs: { dense: "", small: "" } },
                      _vm._l(_vm.comments[_vm.thread._id], function (comment) {
                        return _c("PharmacistThreadComment", {
                          key: comment.comment_id,
                          attrs: { comment: comment, threadId: _vm.thread._id },
                        })
                      }),
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "d-flex align-baseline" },
                  [
                    _c("v-text-field", {
                      ref: _vm.thread._id,
                      staticClass: "mt-3 add-comment",
                      attrs: {
                        placeholder: "Kommentar hinzufügen",
                        "auto-grow": "",
                        outlined: "",
                        "hide-details": "",
                        dense: "",
                        rounded: "",
                      },
                      on: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.submitComment.apply(null, arguments)
                        },
                      },
                      model: {
                        value: _vm.commentText,
                        callback: function ($$v) {
                          _vm.commentText = $$v
                        },
                        expression: "commentText",
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "v-btn",
                      {
                        staticClass: "ml-2 rounded-xl",
                        attrs: {
                          disabled:
                            !_vm.commentText?.trim().length || _vm.isLoading,
                          color: "primary",
                          small: "",
                        },
                        on: { click: _vm.submitComment },
                      },
                      [_vm._v("senden")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }