var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "vuetify-wrapper v-application" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", [_c("jobs-overview-table")], 1),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "v-application" }, [
      _c("h2", { staticClass: "my-5" }, [_vm._v("Automated Jobs")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }