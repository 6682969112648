var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-menu",
    {
      attrs: { "min-width": "400", "allow-overflow": "", "max-height": "500" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on, attrs }) {
            return [
              _c(
                "v-btn",
                _vm._g(
                  _vm._b(
                    {
                      attrs: { text: "", depressed: "", fab: "", small: "" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("get-deleted-comments")
                        },
                      },
                    },
                    "v-btn",
                    attrs,
                    false
                  ),
                  on
                ),
                [
                  _c("v-icon", { attrs: { color: "grey darken-2" } }, [
                    _vm._v("mdi-information-outline"),
                  ]),
                ],
                1
              ),
            ]
          },
        },
      ]),
    },
    [
      _vm._v(" "),
      _vm.comments && _vm.comments.length
        ? _c(
            "v-list",
            _vm._l(_vm.comments, function (comment) {
              return _c(
                "v-list-item",
                { staticClass: "d-block" },
                [
                  _c(
                    "v-list-item-content",
                    [
                      _c("v-list-item-title", [
                        _c("h5", { staticClass: "text-h4" }, [
                          _vm._v(_vm._s(comment.author_name)),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("v-list-item-subtitle", [
                        _vm._v(_vm._s(comment.timestamp.split("T")[0])),
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "mt-2 text-h6" }, [
                        _vm._v(_vm._s(comment.message)),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-divider", { attrs: { color: "black" } }),
                ],
                1
              )
            }),
            1
          )
        : _c("v-list", { staticClass: "text-center" }, [
            _vm._v("Nein kommentar"),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }