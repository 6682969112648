var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-timeline-item",
    {
      ref: "timeline",
      staticClass: "comment-box",
      attrs: { small: "" },
      scopedSlots: _vm._u([
        {
          key: "icon",
          fn: function () {
            return [
              _c("v-avatar", { attrs: { size: "40" } }, [
                _c(
                  "object",
                  {
                    attrs: {
                      data: `/media/avatar/${_vm.comment.author_id}/small`,
                      type: "image/jpg",
                    },
                  },
                  [
                    _c(
                      "v-icon",
                      { staticClass: "avatar-bg", attrs: { size: "40" } },
                      [_vm._v("mdi-account-circle")]
                    ),
                  ],
                  1
                ),
              ]),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm._v(" "),
      _c(
        "v-card",
        {
          staticClass: "rounded-xl",
          attrs: {
            "data-comment-id": _vm.comment.comment_id,
            "data-author-id": _vm.comment.author_id,
            "data-parent-id": _vm.comment.parent_id,
            disabled: _vm.isLoading,
            color:
              _vm.comment.author_id === _vm.pharmacist_id
                ? "#f5f5db"
                : "#f2f2f2",
          },
        },
        [
          _c("v-card-title", { staticClass: "justify-space-between" }, [
            _vm._v(
              "\n            " +
                _vm._s(_vm.comment.author_name) +
                "\n            "
            ),
            !_vm.isEditing
              ? _c(
                  "span",
                  [
                    _vm.currentUser._id === _vm.comment.author_id
                      ? _c(
                          "v-icon",
                          {
                            staticClass: "edit-comment",
                            attrs: { size: "27" },
                            on: {
                              click: function ($event) {
                                _vm.isEditing = true
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                    mdi-pencil\n                "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.currentUser._id === _vm.comment.author_id
                      ? _c(
                          "v-icon",
                          {
                            staticClass: "delete-comment",
                            attrs: { size: "30" },
                            on: {
                              click: function ($event) {
                                _vm.isDeleteCommentDialogOpen = true
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                    mdi-close\n                "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("DeleteCommentConfirmationDialog", {
                      on: {
                        "confirm-deletion": function ($event) {
                          return _vm.deleteComment({
                            threadId: _vm.threadId,
                            commentId: _vm.comment.comment_id,
                            parentId: _vm.comment.parent_id,
                          })
                        },
                      },
                      model: {
                        value: _vm.isDeleteCommentDialogOpen,
                        callback: function ($$v) {
                          _vm.isDeleteCommentDialogOpen = $$v
                        },
                        expression: "isDeleteCommentDialogOpen",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("v-card-subtitle", [
            _c("span", [_vm._v(_vm._s(_vm.formatDate(_vm.comment.timestamp)))]),
            _vm._v(" "),
            _c("span", { staticClass: "ml-2" }, [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.comment.edited ? "(Bearbeitet)" : "") +
                  "\n            "
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("v-card-text", { staticClass: "pb-0" }, [
            !_vm.isEditing
              ? _c("span", { staticClass: "text-body-1 text" }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.comment.message) +
                      "\n            "
                  ),
                ])
              : _c(
                  "span",
                  [
                    _c("v-text-field", {
                      ref: _vm.comment.comment_id,
                      staticClass: "edit-field",
                      attrs: {
                        value: _vm.comment.message,
                        disabled: _vm.isLoading,
                        outlined: "",
                        "hide-details": "",
                        dense: "",
                        rounded: "",
                      },
                      on: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.edit.apply(null, arguments)
                        },
                      },
                    }),
                  ],
                  1
                ),
            _vm._v(" "),
            _c("div", [
              !_vm.comment.parent_id && !_vm.isReplying && !_vm.isEditing
                ? _c(
                    "a",
                    {
                      staticClass: "d-inline-block reply",
                      attrs: { link: "" },
                      on: {
                        click: function ($event) {
                          _vm.isReplying = true
                        },
                      },
                    },
                    [_vm._v(" Antworten\n                ")]
                  )
                : _vm._e(),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "v-card-actions",
            { staticClass: "comment-actions" },
            [
              _vm.isEditing
                ? _c(
                    "v-btn",
                    {
                      staticClass: "mt-2 mr-2 rounded-xl",
                      attrs: {
                        disabled: _vm.isLoading,
                        color: "primary",
                        small: "",
                      },
                      on: { click: _vm.edit },
                    },
                    [_vm._v("\n                Bearbeiten\n            ")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isEditing
                ? _c(
                    "v-btn",
                    {
                      staticClass: "mt-2 ml-2 rounded-xl",
                      attrs: { small: "" },
                      on: {
                        click: function ($event) {
                          _vm.isEditing = false
                        },
                      },
                    },
                    [_vm._v("Abbrechen")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.replies[_vm.comment.comment_id]
        ? _c(
            "v-timeline",
            {
              staticClass: "ml-n12 ml-sm-n8 ml-md-0",
              attrs: { dense: "", small: "" },
            },
            _vm._l(_vm.replies[_vm.comment.comment_id], function (reply) {
              return _c("PharmacistThreadComment", {
                key: reply.comment_id,
                attrs: {
                  comment: reply,
                  threadId: _vm.threadId,
                  pharmacist_id: _vm.pharmacist_id,
                },
              })
            }),
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.comment.parent_id && _vm.isReplying
        ? _c(
            "div",
            { staticClass: "d-sm-flex align-baseline" },
            [
              _c("v-text-field", {
                ref: _vm.comment.comment_id,
                staticClass: "mt-4 add-reply",
                attrs: {
                  disabled: _vm.isLoading,
                  placeholder: "Antworten",
                  "hide-details": "",
                  dense: "",
                  outlined: "",
                  rounded: "",
                },
                on: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.addReply.apply(null, arguments)
                  },
                },
                model: {
                  value: _vm.replyText,
                  callback: function ($$v) {
                    _vm.replyText = $$v
                  },
                  expression: "replyText",
                },
              }),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  staticClass: "ml-2 mt-2 mt-sm-0 rounded-xl",
                  attrs: {
                    disabled: !_vm.replyText?.trim().length || _vm.isLoading,
                    color: "primary",
                    small: "",
                  },
                  on: { click: _vm.addReply },
                },
                [_vm._v("senden")]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }