<template>
    <v-dialog
        v-model="isOpen"
        max-width="1300px"
        content-class="v-application v-application--is-ltr vuetify-wrapper"
        :fullscreen="!$vuetify.breakpoint.mdAndUp"
    >
        <v-card class="overflow-auto" color="#f3f3f3">
            <v-card-text>
                <PharmacistThreadsList
                    :pharmacist_id="pharmacistId"
                    :admin_id="currentUser._id"
                    :pharmacy_name="pharmacyName"
                    :appointment_id="appointmentId"
                    :appointment_date="appointmentDate"
                />
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import PharmacistThreadsList from "./PharmacistThreadsList.vue";
import { mapGetters } from 'vuex';

export default {
    components: {
        PharmacistThreadsList,
    },
    props: ['value', 'pharmacistId', 'pharmacyName', 'appointmentId', 'appointmentDate'],
    computed: {
        isOpen: {
            get() {
                return this.value;
            },
            set(state) {
                this.$emit("input", state);
            },
        },
        ...mapGetters(['currentUser']),
    },
}
</script>
<style>

</style>