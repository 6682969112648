<template>
    <v-card
        :loading="isLoading"
        :disabled="status === 'closed' || isLoading"
        :data-thread-id="thread._id"
        class="px-4 py-4 mt-5 rounded-lg"
        outlined
    >
        <v-list-item class="grow">
            <v-list-item-avatar size="60" color="grey darken-3">
                <object
                    :data="`/media/avatar/${thread.participants.admin}/small`"
                    type="image/jpg"
                >
                    <v-icon size="60">mdi-account-circle</v-icon>
                </object>
            </v-list-item-avatar>
            <v-list-item-content>
                <v-list-item-title class="d-flex justify-space-between text-h5">
                    <span>{{ thread.author_name }}</span>
                </v-list-item-title>
                <v-list-item-subtitle>
                    {{ formatDate(thread.created_at) }}
                </v-list-item-subtitle>
            </v-list-item-content>
            <div>
                <v-btn
                    v-if="status === 'open'"
                    @click="changeThreadStatus('closed')"
                    text
                    depressed
                    fab
                    small
                >
                    <v-icon size="30">mdi-cancel</v-icon>
                </v-btn>
                <v-btn
                    v-else
                    @click="changeThreadStatus('open')"
                    class="reopen"
                    outlined
                    rounded
                    small
                >
                    offene
                </v-btn>
            </div>
        </v-list-item>

        <v-card-text class="text-h6 d-flex justify-space-between">
            <span>
                {{ thread.title }}
            </span>
            <div>
                <PharmacistThreadDeletedComments
                    @get-deleted-comments="getDeletedComments"
                    :comments="deletedComments"
                />
                <v-btn @click="isExpanded = !isExpanded"
                    depressed
                    class="px-0 expand"
                    min-width="30"
                    width="30"
                >
                    <v-icon size="30">
                        {{ isExpanded ? 'mdi-menu-down' : 'mdi-menu-up' }}
                    </v-icon>
                </v-btn>
            </div>
        </v-card-text>

        <v-card-actions class="v-application d-block">
            <v-expand-transition>
                <section v-show="isExpanded">
                    <v-timeline v-if="comments[thread._id]" dense small>
                        <PharmacistThreadComment
                            v-for="comment in comments[thread._id]"
                            :key="comment.comment_id"
                            :comment="comment"
                            :threadId="thread._id"
                        />
                    </v-timeline>
                    <div class="d-flex align-baseline">
                        <v-text-field
                            v-model="commentText"
                            :ref="thread._id"
                            @keyup.enter="submitComment"
                            class="mt-3 add-comment"
                            placeholder="Kommentar hinzufügen"
                            auto-grow
                            outlined
                            hide-details
                            dense
                            rounded
                        />
                        <v-btn
                            @click="submitComment"
                            :disabled="!commentText?.trim().length || isLoading"
                            color="primary"
                            class="ml-2 rounded-xl"
                            small
                        >senden</v-btn>

                    </div>
                </section>
            </v-expand-transition>
        </v-card-actions>
    </v-card>
</template>
<script>
import { mapMutations, mapActions, mapState } from 'vuex';
import axios from 'axios';
import PharmacistThreadComment from "./ThreadComment.vue";
import PharmacistThreadDeletedComments from "./ThreadDeletedComments.vue";

export default {
    components: {
        PharmacistThreadDeletedComments,
        PharmacistThreadComment
    },
    props: {
        thread: Object
    },
    data() {
        return {
            commentText: '',
            status: this.thread.status,
            isExpanded: this.thread.status === "open" ? true : false,
            isLoading: false,
            deletedComments: []
        }
    },
    methods: {
        async changeThreadStatus(status='open') {
            this.isLoading = true;

            try {
                const response = await axios.put(`/api/threads/${this.thread._id}/status_update`, {
                    status
                });
                if (response.status >= 400 || response.data[1] >= 400) throw new Error();

                this.status = status;
                this.isExpanded = status === "open" ? true : false;
            } catch (error) {
                this.alertError()
            } finally {
                this.isLoading = false;
            }
        },

        async getDeletedComments() {
            this.isLoading = true;

            try {
                const response = await axios(`/api/threads/${this.thread._id}/deleted_comments`);
                if (response.status >= 400 || response.data[1] >= 400) throw new Error();
                this.deletedComments = response.data;
            } catch (error) {
                this.alertError()
            } finally {
                this.isLoading = false;
            }
        },

        async submitComment() {
            if (!this.commentText?.trim().length) return
            this.isLoading = true;

            await this.addComment({
                threadId: this.thread._id,
                text: this.commentText
            });

            this.commentText = '';
            this.isLoading = false;
        },

        formatDate(date) {
            return moment(date).format('DD-MM-YYYY, HH:mm');
        },
        ...mapMutations({
            alertError: 'alert/error',
        }),
        ...mapActions({
            addComment: 'threads/addComment'
        })
    },
    computed: {
        ...mapState('threads', ['comments']),
    },
}
</script>
<style>

</style>