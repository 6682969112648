var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "vuetify-wrapper mx-auto" },
    [
      _c(
        "v-btn",
        {
          staticClass: "pharmacists-requests",
          attrs: { "min-width": "30", fab: "", small: "" },
          on: {
            click: function ($event) {
              _vm.isDialogOpen = true
            },
          },
        },
        [
          _c("v-icon", [_vm._v("mdi-account-multiple")]),
          _vm._v(" "),
          _vm.num_of_requests != 0
            ? _c("span", { staticClass: "pharmacists-requests-count" }, [
                _vm._v(
                  "\n            " + _vm._s(_vm.num_of_requests) + "\n        "
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: {
            scrollable: "",
            "content-class": "v-application",
            "max-width": "90vw",
          },
          model: {
            value: _vm.isDialogOpen,
            callback: function ($$v) {
              _vm.isDialogOpen = $$v
            },
            expression: "isDialogOpen",
          },
        },
        [
          _c(
            "v-card",
            {
              attrs: {
                disabled: _vm.isLoading,
                loading: _vm.isLoading,
                elevation: "2",
              },
            },
            [
              _c("v-card-title", [_vm._v("Anfragen durch Freiberufler")]),
              _vm._v(" "),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "h5", attrs: { cols: "12" } },
                        [
                          _vm._v(
                            "Freiberufler Können Anfragen senden, die hier gelistet werden"
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("div", [
                            _c("b", [_vm._v("Apotheke:")]),
                            _vm._v(" "),
                            _c(
                              "a",
                              {
                                attrs: {
                                  href: `/pharmacies/${_vm.pharmacy._id}`,
                                  target: "_blank",
                                },
                              },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(_vm.pharmacy.name) +
                                    "\n                            "
                                ),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            { attrs: { "no-gutters": "" } },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "font-weight-bold",
                                  attrs: { cols: "auto" },
                                },
                                [_vm._v("Termin:")]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                { staticClass: "ml-3" },
                                _vm._l(_vm.group, function (appointment) {
                                  return _c("div", [
                                    _c(
                                      "span",
                                      {
                                        class:
                                          appointment.commited && "committed",
                                      },
                                      [
                                        _vm._v(
                                          "\n                                        " +
                                            _vm._s(
                                              _vm.formatDate(
                                                appointment.date,
                                                "DD-MM-yyyy"
                                              )
                                            ) +
                                            "\n                                    "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "ml-2" }, [
                                      _vm._v(" " + _vm._s(appointment.time)),
                                    ]),
                                  ])
                                }),
                                0
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            { attrs: { "no-gutters": "" } },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "font-weight-bold",
                                  attrs: { cols: "auto" },
                                },
                                [_vm._v("HBA zwingend notwendig: ")]
                              ),
                              _vm._v(" "),
                              _c("v-col", { staticClass: "ml-3" }, [
                                _vm._v(
                                  _vm._s(_vm.appointment.hba ? "ja" : "nein")
                                ),
                              ]),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            { attrs: { "no-gutters": "" } },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "font-weight-bold",
                                  attrs: { cols: "auto" },
                                },
                                [_vm._v("WWS: ")]
                              ),
                              _vm._v(" "),
                              _c("v-col", { staticClass: "ml-2" }, [
                                _vm._v(_vm._s(_vm.pharmacy.erp)),
                              ]),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            { attrs: { "no-gutters": "" } },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "font-weight-bold",
                                  attrs: { cols: "auto" },
                                },
                                [_vm._v("Direkt Zusage: ")]
                              ),
                              _vm._v(" "),
                              _c("v-col", { staticClass: "ml-2" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.pharmacy.no_approval_required
                                      ? "ja"
                                      : "nein"
                                  )
                                ),
                              ]),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.assignedPharmacist?._id
                            ? _c(
                                "v-row",
                                { attrs: { "no-gutters": "" } },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "font-weight-bold",
                                      attrs: { cols: "auto" },
                                    },
                                    [_vm._v("Freiberufler: ")]
                                  ),
                                  _vm._v(" "),
                                  _c("v-col", { staticClass: "ml-2" }, [
                                    _c(
                                      "a",
                                      {
                                        attrs: {
                                          href: `/pharmacists/${_vm.assignedPharmacist._id}`,
                                          target: "_blank",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                    " +
                                            _vm._s(
                                              _vm.assignedPharmacist.firstname
                                            ) +
                                            " " +
                                            _vm._s(
                                              _vm.assignedPharmacist.lastname
                                            ) +
                                            "\n                                "
                                        ),
                                      ]
                                    ),
                                  ]),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-simple-table", {
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function () {
                                  return [
                                    _c("thead", [
                                      _c("tr", [
                                        _c("th", [_vm._v("Name")]),
                                        _vm._v(" "),
                                        _c("th", [_vm._v("Datum der Anfrage")]),
                                        _vm._v(" "),
                                        _c("th", [
                                          _vm._v("Entfernung/ Fahrkosten"),
                                        ]),
                                        _vm._v(" "),
                                        _c("th", [_vm._v("WWS | HBA")]),
                                        _vm._v(" "),
                                        _c("th", [_vm._v("war schonmal da?")]),
                                        _vm._v(" "),
                                        _c("th", [
                                          _vm._v("Anmerkungen /Kommentar"),
                                        ]),
                                        _vm._v(" "),
                                        _c("th", [_vm._v("Auswählen/Absage")]),
                                        _vm._v(" "),
                                        _c("th", [_vm._v("Status")]),
                                      ]),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "tbody",
                                      _vm._l(
                                        _vm.requests,
                                        function (
                                          {
                                            pharmacist,
                                            requestMessage,
                                            requestStatus,
                                            requestDate,
                                            knownPharmacist,
                                            costs,
                                            distance,
                                            is_capped,
                                            disableAcceptance,
                                            disableRejection,
                                          },
                                          idx
                                        ) {
                                          return _c(
                                            "tr",
                                            {
                                              class:
                                                _vm.assignedPharmacist?._id ===
                                                pharmacist._id
                                                  ? "already-allocated"
                                                  : "",
                                              attrs: {
                                                "data-id": pharmacist._id,
                                              },
                                            },
                                            [
                                              _c("td", [
                                                _c(
                                                  "div",
                                                  {
                                                    class:
                                                      _vm.getPharmacistAvailabilityStatus(
                                                        pharmacist
                                                      ).classes,
                                                  },
                                                  [
                                                    _vm.assignedPharmacist
                                                      ?._id === pharmacist._id
                                                      ? _c(
                                                          "v-icon",
                                                          {
                                                            staticClass:
                                                              "mb-1 mr-1",
                                                            attrs: {
                                                              small: "",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                                    mdi-check-circle\n                                                "
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    _c(
                                                      "a",
                                                      {
                                                        attrs: {
                                                          href: `/pharmacists/${pharmacist._id}`,
                                                          target: "_blank",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                                    " +
                                                            _vm._s(
                                                              `${pharmacist.firstname} ${pharmacist.lastname}`
                                                            ) +
                                                            "\n                                                "
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]),
                                              _vm._v(" "),
                                              _c("td", [
                                                _vm._v(_vm._s(requestDate)),
                                              ]),
                                              _vm._v(" "),
                                              _c(
                                                "td",
                                                {
                                                  style: {
                                                    color: is_capped
                                                      ? "red"
                                                      : "green",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                            " +
                                                      _vm._s(distance) +
                                                      " km / " +
                                                      _vm._s(costs) +
                                                      " €\n                                        "
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c("td", [
                                                _c(
                                                  "span",
                                                  {
                                                    style: {
                                                      color:
                                                        parseInt(
                                                          pharmacist
                                                            .erp_expertise[
                                                            _vm.pharmacy.erp
                                                          ]
                                                        ) >= 2 ||
                                                        pharmacist.erp_counts[
                                                          _vm.pharmacy.erp
                                                        ] >= 2
                                                          ? "green"
                                                          : "red",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                                " +
                                                        _vm._s(
                                                          pharmacist
                                                            .erp_expertise[
                                                            _vm.pharmacy.erp
                                                          ]
                                                        ) +
                                                        "/3 (" +
                                                        _vm._s(
                                                          pharmacist.erp_counts[
                                                            _vm.pharmacy.erp
                                                          ]
                                                        ) +
                                                        ")\n                                            "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(
                                                  "\n                                            |\n                                            "
                                                ),
                                                _c(
                                                  "span",
                                                  {
                                                    style: {
                                                      color:
                                                        _vm.appointment.hba &&
                                                        !pharmacist.has_hba
                                                          ? "red"
                                                          : "green",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                                " +
                                                        _vm._s(
                                                          pharmacist.has_hba
                                                            ? "HBA vorhanden"
                                                            : "Kein HBA"
                                                        ) +
                                                        "\n                                            "
                                                    ),
                                                  ]
                                                ),
                                              ]),
                                              _vm._v(" "),
                                              _c(
                                                "td",
                                                {
                                                  style: {
                                                    color:
                                                      knownPharmacist === "Ja"
                                                        ? "green"
                                                        : "red",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                            " +
                                                      _vm._s(knownPharmacist) +
                                                      "\n                                        "
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "td",
                                                { staticClass: "message" },
                                                [_vm._v(_vm._s(requestMessage))]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "td",
                                                [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      staticClass: "accept",
                                                      attrs: {
                                                        disabled:
                                                          disableAcceptance ||
                                                          !_vm.getPharmacistAvailabilityStatus(
                                                            pharmacist
                                                          ).canAssign ||
                                                          _vm.appointment
                                                            .wf_state ==
                                                            "allocated",
                                                        "x-small": "",
                                                        color: "success",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.accept(
                                                            pharmacist
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("Auswählen")]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      staticClass: "reject",
                                                      attrs: {
                                                        disabled:
                                                          disableRejection,
                                                        "x-small": "",
                                                        color: "error",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.reject(
                                                            pharmacist
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("Absage")]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      staticClass:
                                                        "open-threads",
                                                      attrs: {
                                                        "x-small": "",
                                                        dark: "",
                                                        color: "blue",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          _vm.requests[
                                                            idx
                                                          ].isThreadsDialogOpen = true
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "Starte eine Konversation"
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "PharmacistThreadsDialog",
                                                    {
                                                      attrs: {
                                                        pharmacistId:
                                                          pharmacist._id,
                                                        pharmacyName:
                                                          _vm.pharmacy.name,
                                                        appointmentId:
                                                          _vm.appointment._id,
                                                        appointmentDate:
                                                          _vm.formatDate(
                                                            _vm.appointment
                                                              .date,
                                                            "DD-MM-yyyy"
                                                          ),
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.requests[idx]
                                                            .isThreadsDialogOpen,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.requests[idx],
                                                            "isThreadsDialogOpen",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "requests[idx].isThreadsDialogOpen",
                                                      },
                                                    }
                                                  ),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "td",
                                                {
                                                  staticClass: "status",
                                                  style: {
                                                    color:
                                                      requestStatus.toLowerCase() ===
                                                      "accepted"
                                                        ? "green"
                                                        : "red",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                            " +
                                                      _vm._s(
                                                        _vm.getStatusName(
                                                          requestStatus
                                                        )
                                                      ) +
                                                      "\n                                        "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }