<template>
    <div class="vuetify-wrapper v-application">
        <span class="v-application">
            <h2 class="my-5">Automated Jobs</h2>
        </span>
        <div>
            <jobs-overview-table />
        </div>
    </div>
</template>


<script>
    import JobsOverviewTable from './JobsOverviewTable.vue';

    export default {
        props: {},
        components: {
            'jobs-overview-table': JobsOverviewTable
        }
    }
</script>

<style>
    .vuetify-wrapper th {
        vertical-align: middle;
    }
</style>
