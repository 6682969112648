import Vue from 'vue';
import axios from 'axios';

const state = {
    threads : {},
    comments: {},
    replies: {}
};

const getters = {
    getPharmacistThreads: state => pharmacistId => {
        if (!state.threads[pharmacistId]) return []
        return state.threads[pharmacistId]
    },
    getPharmacistThreadsByAppointmentId: state => (pharmacistId, appointmentId) => {
        if (!state.threads[pharmacistId]) return []
        return state.threads[pharmacistId].filter(thread => {
            return thread.reference === appointmentId
        })
    },
}

const actions = {
    async getThreads({ commit, dispatch }, pharmacistId) {
        try {
            const response = await axios.get(`/api/threads/pharmacist/${pharmacistId}`);
            commit('setThreads', {threads: response.data, pharmacistId });
            return response.data
        } catch (error) {
            dispatch('alert/error', undefined, { root: true })
        }
    },
    async addThread({ commit, dispatch }, {
        pharmacistId,
        appointmentId,
        adminId,
        threadText
    }) {
        try {
            const response = await axios.post(`/api/threads`, {
                "title": threadText,
                "participants": {
                    "pharmacist": pharmacistId,
                    "admin": adminId
                },
                "reference": appointmentId
            });
            if (response.status >= 400 || response.data[1] >= 400) throw new Error();
            commit('addThread', {
                thread: response.data,
                pharmacistId,
            });
            return response.data
        } catch (error) {
            dispatch('alert/error', undefined, { root: true })
        }
    },
    async addComment({ commit, dispatch }, { threadId, text }) {
        try {
            const response = await axios.post(`/api/threads/${threadId}/comments`, {
                message: text
            });
            commit('addComment', { threadId, comment: response.data })
            return response.data
        } catch (error) {
            dispatch('alert/error', undefined, { root: true })
        }
    },
    async editComment({ commit, dispatch }, { threadId, commentId, text }) {
        try {
            const response = await axios.put(`/api/threads/${threadId}/comments/${commentId}`, {
                message: text
            });
            commit('editComment', {
                threadId,
                commentId,
                text: response.data.message
            });
            return response.data
        } catch (error) {
            dispatch('alert/error', undefined, { root: true })
        }
    },
    async deleteComment({ commit, dispatch }, { threadId, commentId, parentId }) {
        try {
            const response = await axios.delete(`/api/threads/${threadId}/comments_del/${commentId}`);
            !parentId
                ? commit('deleteComment', { threadId, commentId })
                : commit('deleteCommentReply', { threadId, commentId, parentId });
            return response.data
        } catch (error) {
            dispatch('alert/error', undefined, { root: true })
        }
    },
    async addCommentReply({ commit, dispatch }, { threadId, parentId, text }) {
        try {
            const response = await axios.post(`/api/threads/${threadId}/comments/${parentId}/reply`, {
                message: text,
            });
            commit('addCommentReply', {
                threadId,
                parentId,
                reply: response.data
            });
            return response.data
        } catch (error) {
            dispatch('alert/error', undefined, { root: true })
        }
    },
    async editCommentReply({ commit, dispatch }, { threadId, commentId, parentId, text }) {
        try {
            const response = await axios.put(`/api/threads/${threadId}/comments/${commentId}`, {
                message: text,
                parent_id: parentId
            });
            commit('editCommentReply', {
                threadId,
                commentId,
                parentId,
                text: response.data.message
            });
            return response.data
        } catch (error) {
            dispatch('alert/error', undefined, { root: true })
        }
    },
};

const mutations = {
    setThreads(state, { threads, pharmacistId }) {
        threads.forEach(thread => {
            // Set threads
            if (!state.threads[pharmacistId]) {
                Vue.set(state.threads, pharmacistId, [thread])
            } else {
                state.threads[pharmacistId].push(thread)
            }
            // Set comments
            thread.comments
                .filter(comment => !comment.parent_id)
                .forEach(comment => {
                    if (!state.comments[thread._id]) {
                        Vue.set(state.comments, thread._id, [comment])
                    } else {
                        state.comments[thread._id].push(comment)
                    }
            });
            // Set replies
            thread.comments
                .filter(comment => comment.parent_id)
                .forEach(reply => {
                    if (!state.replies[reply.parent_id]) {
                        Vue.set(state.replies, reply.parent_id, [reply])
                    } else {
                        state.replies[reply.parent_id].push(reply)
                    }
            });
        });
    },
    addThread(state, { pharmacistId, thread }) {
        !state.threads[pharmacistId]
            ? Vue.set(state.threads, pharmacistId, [thread])
            : state.threads[pharmacistId].push(thread)
    },
    addComment(state, { threadId, comment }) {
        !state.comments[threadId]
            ? Vue.set(state.comments, threadId, [comment])
            : state.comments[threadId].push(comment)
    },
    editComment(state, { threadId, commentId, text }) {
        const comment = state.comments[threadId].find(({comment_id}) => comment_id === commentId);
        comment.message = text;
        comment.edited = true
    },
    deleteComment(state, { threadId, commentId }) {
        const commentIdx = state.comments[threadId].findIndex(({comment_id}) => comment_id === commentId);
        state.comments[threadId].splice(commentIdx, 1);
    },
    addCommentReply(state, { threadId, parentId, reply }) {
        !state.replies[parentId]
            ? Vue.set(state.replies, parentId, [reply])
            : state.replies[parentId].push(reply);
    },
    editCommentReply(state, { commentId, parentId, text }) {
        const reply = state.replies[parentId].find(({comment_id}) => comment_id === commentId);
        reply.message = text;
        reply.edited = true
    },
    deleteCommentReply(state, { commentId, parentId }) {
        const replyIdx = state.replies[parentId].findIndex(({comment_id}) => comment_id === commentId);
        state.replies[parentId].splice(replyIdx, 1);
    },

    changeThreadLoadingState(state, { threadId, isLoading }) {
        state.threads.forEach(thread => {
            if (thread._id === threadId) thread.isLoading = isLoading
        })
    },
};

export const threads = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}